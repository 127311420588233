exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-p-1-index-tsx": () => import("./../../../src/pages/_p1/index.tsx" /* webpackChunkName: "component---src-pages-p-1-index-tsx" */),
  "component---src-pages-p-1-videos-espanol-tsx": () => import("./../../../src/pages/_p1/videos/espanol.tsx" /* webpackChunkName: "component---src-pages-p-1-videos-espanol-tsx" */),
  "component---src-pages-p-2-emotionally-tsx": () => import("./../../../src/pages/_p2/emotionally.tsx" /* webpackChunkName: "component---src-pages-p-2-emotionally-tsx" */),
  "component---src-pages-p-2-financially-tsx": () => import("./../../../src/pages/_p2/financially.tsx" /* webpackChunkName: "component---src-pages-p-2-financially-tsx" */),
  "component---src-pages-p-2-index-tsx": () => import("./../../../src/pages/_p2/index.tsx" /* webpackChunkName: "component---src-pages-p-2-index-tsx" */),
  "component---src-pages-p-2-physically-tsx": () => import("./../../../src/pages/_p2/physically.tsx" /* webpackChunkName: "component---src-pages-p-2-physically-tsx" */)
}

